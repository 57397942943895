/** @jsx jsx */
import { jsx, Heading, Link as TLink } from "theme-ui"
import { Link } from "gatsby"
import { Flex } from "@theme-ui/components"
import Layout from "./layout";
import Listing from "./listing";
import useMinimalBlogConfig from "../hooks/use-minimal-blog-config"
import replaceSlashes from "../utils/replaceSlashes"
import SEO from "./seo"
import { WebSite } from "schema-dts";
import { helmetJsonLdProp } from "react-schemaorg";
import useSiteMetadata from "../hooks/use-site-metadata";

type PostsProps = {
    posts: {
        slug: string
        title: string
        date: string
        excerpt: string
        description: string
        timeToRead?: number
        tags?: {
            name: string
            slug: string
        }[]
    }[]
    [key: string]: any
}

const BlogCore = ({ posts }: PostsProps) => {
    const { tagsPath, basePath } = useMinimalBlogConfig();
    const { siteTitle, siteUrl } = useSiteMetadata();

    const jsonLd = helmetJsonLdProp<WebSite>({
        "@context": "https://schema.org",
        "@type": "WebSite",
        "name": siteTitle,
        "url": siteUrl
    });

    return (
        <Layout>
            <SEO title="Home" script={jsonLd} />
            <Flex sx={{ alignItems: `center`, justifyContent: `space-between`, flexFlow: `wrap` }}>
                <Heading as="h1" variant="styles.h1">
                    Blog
                </Heading>
                <TLink
                    as={Link}
                    sx={{ variant: `links.secondary`, marginY: 2 }}
                    to={replaceSlashes(`/${basePath}/${tagsPath}`)}
                >
                    View all tags
                </TLink>
            </Flex>
            <Listing posts={posts} sx={{ mt: [3, 4] }} />
        </Layout>
    )
}

export default BlogCore
